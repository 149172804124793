<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card>
          <template v-slot:heading>
            <h3 class="display-2">Aktivitas Negatif</h3>
          </template>
          <v-toolbar flat>
            <div style="max-width:400px">
              <v-autocomplete v-model="selectSKPD" :items="skpd" label="SKPD" item-text="v" item-value="k" hide-details></v-autocomplete>
            </div>
            <span class="ml-5">Filter: </span>
            <v-switch
              v-model="filterPegawai"
              color="primary"
              label="Pegawai E-RK"
              class="mt-5 mx-5"
            ></v-switch>
            <v-switch
              v-model="filterSemua"
              color="primary"
              label="Semua Pegawai"
              class="mt-5"
            ></v-switch>
            <v-spacer></v-spacer>
            <div>
              <v-text-field v-model="search" hide-details label="Cari" append-icon="mdi-magnify"></v-text-field>
            </div>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :footer-props="{'items-per-page-options':[10,15,20]}"
          >
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.nip="{ item }">
              <br>
              <div>
                <b> {{item.peg_nama}} </b>
                <br>
                {{item.peg_nip}}
                <br>
                <b><font :color="item.status == 'Belum Komplit' ? 'red' : 'green'">{{item.status}}</font></b>
              </div>
              <br>
            </template>
            <template v-slot:item.jabatan="{ item }">
              <div>{{ item.jenis_jabatan == 'S' ? 'Struktural' : (item.jenis_jabatan == 'F' ? 'Fungsional Umum' : (item.jenis_jabatan == 'T' ? 'Fungsional Tertentu' : '')) }} {{ item.eselon  }}<br>{{ item.nama_golongan }}</div>
            </template>
            <template v-slot:item.job_value="{ item }">
              <div><b>{{item.job_value}}</b> <br> {{item.nama_jabatan}} <br> {{item.kelas_jabatan}}</div>
            </template>
            <template v-slot:item.aksi="{item}">
              <v-btn color="yellow darken-1" x-small @click="lihat(item)"><v-icon small>mdi-magnify</v-icon>Lihat Aktivitas Negatif</v-btn>
              <v-btn color="primary" x-small @click="tambah(item)"><v-icon small>mdi-plus</v-icon>Tambah Aktivitas Negatif</v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogLihat" max-width="900px">
      <v-card>
        <v-card-title>
          <v-avatar class="rounded-circle" tile size="100"> <v-img :src="item.foto"></v-img></v-avatar>
          <div class="ml-3">
            <h3 class="display-1 orange--text text--darken-2">
              {{item.peg_nama}}
            </h3>
            <h3 class="display-1 font-weight-light black--text">
              NIP. {{item.peg_nip}}
            </h3>
            <h3 class="display-1 font-weight-light green--text">
              {{item.nama_jabatan}}
            </h3>
          </div>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersDataAktivitas"
            :items="dataAktivitas"
            :loading="loadingDataAktivitas"
            :server-items-length="totalKeseluruhanDataAktivitas"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :footer-props="{'items-per-page-options':[10,15,20]}"
          >
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.tanggal="{item}">
              {{gFD(item.tanggal)}}
            </template>
            <template v-slot:item.aksi="{item}">
              <div v-if="item.tampil">
                <br>
                <a x-small @click="openDetail(item)"><v-icon>mdi-magnify</v-icon> Detail</a><br>
                <a x-small @click="editAktivitas(item)"><v-icon>mdi-pencil</v-icon> Edit</a><br>
                <a x-small @click="hapusAktivitas(item)"><v-icon>mdi-delete</v-icon> Hapus</a>
                <br>
                <br>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="yellow darken-1" @click="dialogLihat = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDetail" max-width="700px">
      <v-card>
        <v-card-title>
          <v-avatar class="rounded-circle" tile size="100"> <v-img :src="detailPegawai.foto"></v-img></v-avatar>
          <div class="ml-3">
            <h3 class="display-1 orange--text text--darken-2">
              {{detailPegawai.peg_nama}}
            </h3>
            <h3 class="display-1 font-weight-light black--text">
              NIP. {{detailPegawai.peg_nip}}
            </h3>
            <h3 class="display-1 font-weight-light green--text">
              {{detailPegawai.nama_jabatan}}
            </h3>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="ml-5">
            <v-row>
              <p><b>Jenis: </b></p>&ensp;
              <p>{{ detailItem.jenis }}</p>
            </v-row>
            <v-row>
              <p><b>Tanggal: </b></p>&ensp;
              <p>{{ detailItem.tanggal }}</p>
            </v-row>
            <v-row>
              <p><b>Keterangan: </b></p>&ensp;
              <p>{{ detailItem.keterangan }}</p>
            </v-row>
            <v-row>
              <p><b>Data Pendukung: </b></p>&ensp;
              <div v-for="(a,i) in detailItem.attachment" v-bind:key="i">
                <a :href="url(a)" target="_blank" :key="i">{{a}}</a>
              </div>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="yellow darken-1" @click="dialogDetail = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAktivitasNegatifPegawai" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <v-avatar class="rounded-circle" tile size="100"> <v-img :src="pegawai.foto"></v-img></v-avatar>
          <div class="ml-3">
            <h3 class="display-1 orange--text text--darken-2">
              {{pegawai.peg_nama}}
            </h3>
            <h3 class="display-1 font-weight-light black--text">
              NIP. {{pegawai.peg_nip}}
            </h3>
            <h3 class="display-1 font-weight-light green--text">
              {{pegawai.nama_jabatan}}
            </h3>
          </div>
        </v-card-title>
        <v-card-text>
          <h4>{{ formAktivitasNegatif.id == null ? 'Tambah' : 'Ubah' }} Aktivitas Negatif</h4>
          <div>
            <v-row class="pb-9 pt-3">
              <v-col cols="2">
                <b><label>Pilih Aktivitas Negatif</label></b>
              </v-col>
              <v-col>
                <v-row class="pt-3">
                  <v-col cols="4" v-for="status in jenisAktivitasNegatif" v-bind:key="status" class="pt-0 pb-0">
                    <b>
                    <v-radio-group class="mt-0" v-model="formAktivitasNegatif.jenis" dense>
                      <v-radio :label="status" :value="status"></v-radio>
                    </v-radio-group>
                    </b>
                  </v-col>
                </v-row><br>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row>
              <v-col cols="2">
                <b><label>Tanggal</label></b>
              </v-col>
              <v-col cols="3">
                <v-menu
                  v-model="fromDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="290px"
                  transition="scale-transition"
                  lazy offset-y full-width>
                  <template v-slot:activator="{ on }">
                    <v-text-field v-show="!showItem" label="Tanggal" append-icon="mdi-calendar" outlined dense readonly v-model="formAktivitasNegatif.tanggal" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker
                  locale="in-ID"
                  v-model="formAktivitasNegatif.tanggal"
                  no-title
                  @input="fromDateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row>
              <v-col cols="2">
                <label>Keterangan</label>
              </v-col>
              <v-col>
                <v-textarea outlined dense v-model="debounceText" height="60px"></v-textarea>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row>
              <v-col cols="2">
                <label>Data Pendukung</label>
              </v-col>
              <v-col>
                <file-input ref="fileInput" :helpText="'Upload File'" :errorInput="fileInputErrorFlag" :uploadUrl="uploadUrl" @delFileUploaded="delFormFile" @fileUploaded="addFormFile"></file-input>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="yellow darken-1" @click="dialogAktivitasNegatifPegawai = false">Tutup</v-btn>
          <v-btn color="green darken-1 white--text" :loading="btnLoadingSimpan" @click="simpan()">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AktivitasNegatifService from '@/services/AktivitasNegatifService'
// import PegawaiReviewer from './PegawaiReviewer.vue'
import store from '@/store'
import _g from '../../global'
import axios from 'axios'
import {local} from '@/store/local'
import _ from 'lodash'
import FileInput from '@/components/common/FileInput'
export default {
  components:{
    FileInput
  },
  data(){
    return{
      filterPegawai:true,
      forceUpdate:false,
      loading:false,
      loadingDataAktivitas:false,
      detailData:{},
      skpd:[],
      headers:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'NIP-Nama', value:'nip', sortable:false, class:'header-index-profile'},
        {text:'Jabatan - Pangkat', value:'jabatan', sortable:false, class:'header-index-profile'},
        {text:'Job Value', value:'job_value', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile', width:"20%"},
      ],
      headersDataAktivitas:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Jenis Aktivitas', value:'jenis', sortable:false, class:'header-index-profile'},
        {text:'Tanggal', value:'tanggal', sortable:false, class:'header-index-profile'},
        {text:'Keterangan', value:'keterangan', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile', width:"20%"},
      ],
      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
      selectSKPD:'',
      search: '',
      tableValue:{},
      totalKeseluruhan:0,
      totalKeseluruhanDataAktivitas:0,
      rowPerPage:10,
      page:1,
      dialogLihat: false,
      item:{},
      dialogAktivitasNegatifPegawai: false,
      dataPegawai: {},
      pegawai: {},
      dataAktivitas:{},
      dialogDetail: false,
      detailPegawai: {},
      detailItem: {},
      jenisAktivitasNegatif: [],
      dialogTambah: false,
      fromDateMenu:false,
      showItem:false,
      fileInputErrorFlag:false,
      formAktivitasNegatif: {},
      btnLoadingSimpan:false
    }
  },

  watch:{
    page(val){
      this.tableValue.page = val
      this.updateTable(this.tableValue)
    }
  },

  mounted(){
    this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD, vm.selectBulan, vm.filterPegawai], val => {
      this.page = 1
      this.updateTable({itemsPerPage:this.rowPerPage, satuan_kerja_id:this.selectSKPD, search:val[1], page:this.page, params_sorts:this.filterPegawai})
    })
  },

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
    this.getListSKPD()
    this.getJenisAktivitasNegatif()
  },

  computed:{
    filterSemua:{
      get:function(){
        return !this.filterPegawai
      },
      set:function(val){
        this.filterPegawai = !val
      }
    },
    debounceText: {
      get() { return this.formAktivitasNegatif.keterangan != '' ? this.formAktivitasNegatif.keterangan : this.text; },
      set: _.debounce(function(newValue) {
        this.formAktivitasNegatif.keterangan = newValue
      }, 100)
    },
    uploadUrl(){
      return process.env.VUE_APP_API_URL + '/api/mb-upload/aktivitas-negatif/'
    },
  },

  methods:{
    getListSKPD(){
      var url = "v1/get-data-skpd-satuan-kerja"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_).then(response => {
        let data = []
        _.each(response.data.data, function(value, key, obj){
          data.push({k:key, v: value})
        })
        this.skpd = data
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    updateTable(val){
      var filter = 'erk'
      if(this.filterSemua){
        filter = 'all'
      }

      this.loading = true
      this.tableValue = val ? val: this.tableValue
      this.items = []

      AktivitasNegatifService.getAllData({
        search:this.tableValue.search,
        row_per_page:this.tableValue.itemsPerPage,
        page:this.tableValue.page,
        satuan_kerja_id:this.selectSKPD,
        params_sorts: filter})
      .then((response)=>{
        this.items = response.data.data
        this.totalKeseluruhan = response.data.total_keseluruhan
        console.log(this.items)
        let res = response.data
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loading = false
      })
    },

    lihat(item){
      this.item = item
      this.pegawai = item ? item: this.pegawai
      this.pegawai.search = ''
      this.pegawai.itemsPerPage = this.rowPerPage
      this.pegawai.page = this.page
      this.loadingDataAktivitas = true
      var params = {}
      params.tampil = 'ya'
      params.nip = item.peg_nip
      AktivitasNegatifService.getAktivitas({
        search:this.pegawai.search,
        row_per_page:this.pegawai.itemsPerPage,
        page:this.pegawai.page,
        params: params})
      .then((response)=>{
        this.dataAktivitas = response.data.data
        this.totalKeseluruhanDataAktivitas = response.data.count
        console.log(this.dataAktivitas)
        let res = response.data
        if (res.count > 0){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loadingDataAktivitas = false
        this.dialogLihat = true
      })
    },

    tambah(item){
      this.dataPegawai = item
      this.pegawai = item ? item: this.pegawai
      this.dialogAktivitasNegatifPegawai = true
      this.formAktivitasNegatif = { id: null }
      this.formAktivitasNegatif.peg_nip = item.peg_nip
    },

    openDetail(item){
      this.detailItem = item
      this.detailPegawai = this.pegawai
      this.dialogDetail = true
    },

    editAktivitas(item){
      this.formAktivitasNegatif = { id: item.id }
      this.formAktivitasNegatif = item
      this.dialogAktivitasNegatifPegawai = true
    },

    hapusAktivitas(item){
      if(confirm("Apakah Anda Yakin Akan Menghapus Aktivitas Ini?")){
      this.btnLoadingSimpan = true
      AktivitasNegatifService.hapusAktivitasNegatif({
        id: item.id})
      .then((response)=>{
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Hapus Data Aktivitas Negatif Berhasil'), color:'success'})
          this.reloadDataAktivitasNegatif()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.btnLoadingSimpan = false
        this.dialogAktivitasNegatifPegawai = false
      })
      }
    },

    gFD(d){
      return _g.getFullDate(d)
    },
    url(u){
      var current = this.$store.getters["user/current"]
      var year = current.year
      var base = process.env.VUE_APP_API_URL
      if(base === 'https://mangbagja-demo.rev.web.id/'){
        base = 'https://erk-demo.rev.web.id/'
      }else{
        base = 'https://kinerja.bandung.go.id/'
      }
      return base+year+"/img/aktivitas_negatif/"+u
    },
    getJenisAktivitasNegatif(){
      var url = "v1/aktivitas-negatif/get-jenis"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {api: 1}).then(response => {
        let data = []
        _.each(response.data.data, function(value, key){
          data.push(value)
        })
        this.jenisAktivitasNegatif = data
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    delFormFile(file){
      let regex = /\d+_([\w\s-]+)-[\w\d]+\.(\w+)/
      let filename = file.file.name
      this.formLapor.file = this.formLapor.file.filter((val)=>{
        const match = val.match(regex)
        let curr_filename = match[1]+'.'+match[2]
        if (match){
          return filename != curr_filename
        }
        return false
      })
    },

    addFormFile(file){
      this.formAktivitasNegatif.file.push(file)
      console.log(this.formAktivitasNegatif.file)
    },

    simpan(){
      this.btnLoadingSimpan = true
      AktivitasNegatifService.simpanAktivitasNegatif({
        nip: this.formAktivitasNegatif.peg_nip,
        id: this.formAktivitasNegatif.id,
        jenis: this.formAktivitasNegatif.jenis,
        tanggal: this.formAktivitasNegatif.tanggal,
        keterangan: this.formAktivitasNegatif.keterangan,
        file: this.formAktivitasNegatif.file})
      .then((response)=>{
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Tambah Data Aktivitas Negatif Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.btnLoadingSimpan = false
        this.dialogAktivitasNegatifPegawai = false
      })
    },

    reloadDataAktivitasNegatif(){
      var params = {}
      params.tampil = 'ya'
      params.nip = this.pegawai.peg_nip
      AktivitasNegatifService.getAktivitas({
        search:this.pegawai.search,
        row_per_page:this.pegawai.itemsPerPage,
        page:this.pegawai.page,
        params: params})
      .then((response)=>{
        this.dataAktivitas = response.data.data
        this.totalKeseluruhanDataAktivitas = response.data.count
        console.log(this.dataAktivitas)
        let res = response.data
        if (res.count > 0){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loadingDataAktivitas = false
        this.dialogLihat = true
      })
    }
  },
}
</script>

<style lang="css" scoped>
</style>
