import request from '@/utils/request'
import store from '@/store'
import _g from '../../src/global'

const AktivitasNegatifService = {
  getAllData,
  getAktivitas,
  simpanAktivitasNegatif,
  hapusAktivitasNegatif
}


// function getAllData(cari, row_per_page, page, satuan_kerja_id, params_sorts){
function getAllData(data){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  let sk_id = data.satuan_kerja_id ? data.satuan_kerja_id : 6

  return request({
    url:'/admin/aktivitas-negatif/get-data',
    method:'post',
    data:{
    nip:current.username,
    "order": "peg_nama",
    "order_direction": "asc",
    "page": data.page,
    "perpage": data.row_per_page,
    "search": data.search,
    // "params": data.params,
    "params_sorts": data.params_sorts,
    "params_satuan_kerja_id": sk_id
    }
  })
}

function getAktivitas(data){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  // let sk_id = data.satuan_kerja_id ? data.satuan_kerja_id : 6

  return request({
    url:'/admin/aktivitas-negatif/get-aktivitas',
    method:'post',
    data:{
    nip:current.username,
    // "order": "peg_nama",
    "order_direction": "asc",
    "page": data.page,
    "perpage": data.row_per_page,
    "search": data.search,
    "params": data.params,
    // "params_sorts": data.params_sorts,
    // "params_satuan_kerja_id": sk_id
    }
  })
}

function simpanAktivitasNegatif(data){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url:'/admin/aktivitas-negatif/storeAktivitas',
    method:'post',
    data:{
    admin:current.username,
    nip:data.nip,
    id: data.id,
    jenis: data.jenis,
    tanggal: data.tanggal,
    keterangan: data.keterangan,
    attachment: data.file
    }
  })
}

function hapusAktivitasNegatif(data){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url:'/admin/aktivitas-negatif/hapusNegatif',
    method:'post',
    data:{
    nip:current.username,
    id: data.id
    }
  })
}
export default AktivitasNegatifService